import React from 'react';
import { Link } from 'gatsby';

const four04 = () => (
  <>
    <h1>Page Doesn't Exist</h1>
    <Link to="/">Return Home</Link>
  </>
);

export default four04;
